<template>
  <Container :title="data.name || ''" class="documents">
    <v-btn
      absolute top right fab
      elevation="2" class="d-print-none"
      @click="print"
    >
      <v-icon>mdi-printer</v-icon>
    </v-btn>
    <v-skeleton-loader
      v-if="loading"
      type="paragraph@16"
    />
    <v-card v-else flat class="agreement-text">
      {{ data.text }}
      <v-card-actions v-if="$auth.user()">
        <v-spacer/>
        <v-progress-circular
          v-if="loadingConsent"
          indeterminate
        />
        <v-checkbox
          v-else
          :input-value="!!data.consent"
          :disabled="!!data.consent"
          :label="data.consent
            ? `${$t('agreements.acceptedOn')} ${data.consent.created_at.substring(0, 10)}`
            : $t('agreements.checkToAccept', { name: data.name })"
          @change="consent"
        />
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </Container>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "AgreementPage",
  props: {
    id: { type: String, required: true },
  },
  data() {
    return {
      loading: false,
      loadingConsent: false,
      data: {},
    }
  },
  created() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.loading = true
      this.$http
        .get(`/agreements/${this.id}/`)
        .then((response) => {
          this.loading = false
          this.data = response.data
        })
        .catch(() => {
          this.loading = false
        })
    },
    consent() {
      this.loadingConsent = true
      this.axios
        .post(`/agreements/${this.data.id}/consent/`)
        .then((response) => {
          this.loadingConsent = false
          this.data.consent = response.data
        })
        .catch(() => {
          this.loadingConsent = false
        })
    },
    print() {
      window.print()
    },
  },
})
</script>

<style>
.agreement-text {
  white-space: pre-line;
}
</style>
